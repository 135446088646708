<template>
    <div class="flex justify-end items-center gap-2">


        <Menu as="div" class="relative ml-3">
            <div>
                <button @click="toggleMenu" class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="absolute -inset-1.5" />
                    <span class="sr-only">Open user menu</span>
                    <img :src="`/images/flags/svg/${locale}.svg`" width="24" height="24" :alt="locale" class="inline-block mr-2" />
                </button>
            </div>
            <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <div v-if="menuOpen" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="changeLocale('fr')">
                        <img src="/images/flags/svg/fr.svg" width="30" height="20" alt="Français" class="inline-block mr-2" /> Français
                    </div>
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="changeLocale('en')">
                        <img src="/images/flags/svg/en.svg" width="30" height="20" alt="English" class="inline-block mr-2" /> English
                    </div>
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="changeLocale('es')">
                        <img src="/images/flags/svg/es.svg" width="30" height="20" alt="Español" class="inline-block mr-2" /> Español
                    </div>
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="changeLocale('it')">
                        <img src="/images/flags/svg/it.svg" width="30" height="20" alt="Italiano" class="inline-block mr-2" /> Italiano
                    </div>
                    <div class="block px-4 py-2 text-sm text-gray-700 cursor-pointer" @click="changeLocale('de')">
                        <img src="/images/flags/svg/de.svg" width="30" height="20" alt="Deutsch" class="inline-block mr-2" /> Deutsch
                    </div>
                </div>
            </transition>
        </Menu>
    </div>
</template>

<script setup>

    import { useI18n } from 'vue-i18n';
    import { router } from '@inertiajs/vue3'
    import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
    import {ref} from "vue";

    const { locale } = useI18n();
    const menuOpen = ref(false)

    const toggleMenu = () => {
        menuOpen.value = !menuOpen.value
    }
    function changeLocale(lang) {
        // Stocker la langue dans le localStorage
        localStorage.setItem('language', lang);
        // Mise à jour de la langue de Vue I18n
        locale.value = lang;
        // Redirection vers la nouvelle langue
        router.get(`/${lang}${window.location.pathname.substring(3)}`, {}, {
                                                                                                    preserveState: true,
                                                                                                    preserveScroll: true,
                                                                                                });
        menuOpen.value = !menuOpen.value
    }

</script>
